import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom'
import setAnalyticsData from '../../utils/adobe.js';
import ChatBot from '../../components/Chatbot.js';

function TechnicalError() {

  const location = useLocation()
  const query = new URLSearchParams(location.search)
  const err = query.get('err')
  const cd = query.get('cd')
  const [href, setHref] = useState("mailto:State%20Farm%20Ting%20Support%20%3chome.fire-tingtelematics.664i00@statefarm.com%3e?subject=SF%20Ting%20Enrollment%20Error%20Timeout"
    + "%20")

  useEffect(() => {
    // Load 1x Script
    var oneXScript = document.createElement("script");
    oneXScript.src = process.env.REACT_APP_ONEX_URL + "/en_US/dxl-1x/prod/js/1x.core.js";
    document.body.appendChild(oneXScript);

    setAnalyticsData({
      screenId: 'ting-enrollment-error',
      screenChannel: 'ting'
    })

    if (err && cd) {
      setHref(href + `(${err}:${cd})`)
    } else if (err) {
      setHref(href + `(${err})`)
    } else if (cd) {
      setHref(href + `(${cd})`)
    }
  }, []);

  function clickSupportTechnicalError() {
  }

  return (
    <div className="main-container">
      <head>
        <title>Ting Enrollment - Error</title>

        <link rel="preload stylesheet" href="https://cdn.jsdelivr.net/npm/bootstrap@4.6.0/dist/css/bootstrap.min.css" integrity="sha384-B0vP5xmATw1+K9KRQjQERJvTumQW0nPEzvF6L/Z6nronJ3oUOFUFpCjEUQouq2+l" crossOrigin="anonymous"/>
        <link rel="preload stylesheet" href={process.env.REACT_APP_ONEX_URL + "/en_US/dxl-1x/prod/css/lib/normalize.css"}/>
        <link rel="preload stylesheet" href={process.env.REACT_APP_ONEX_URL + "/en_US/dxl-1x/prod/css/1x.core.css"} type="text/css"/>

        {/* <script src={process.env.REACT_APP_ADOBE_ANALYTICS} async></script> */}
      </head>

      <header data-type="1x-header-auth"></header>

      <main className="main">
        <div className="-oneX-container">
          <div className="-oneX-row pb-5">
            <div className="-oneX-col-12">
              <h1>We are currently experiencing technical difficulties. Please logout and try to enroll again at a later time.</h1>
              <p>If you continue to encounter issues, please contact the <a href={href} style={{textDecoration: 'underline'}} onClick={clickSupportTechnicalError.bind(this)}  className="-oneX-link--inline">State Farm Ting Support Team</a> for assistance.</p>
            </div>
          </div>
          <ChatBot/>
        </div>
      </main>

       <footer data-type="1x-footer"></footer>
       <script rel="preload" src="https://code.jquery.com/jquery-3.5.1.slim.min.js" integrity="sha384-DfXdz2htPH0lsSSs5nCTpuj/zy4C+OGpamoFVy38MVBnE+IbbVYUew+OrCXaRkfj" crossOrigin="anonymous"></script>
       <script rel="preload" src="https://cdn.jsdelivr.net/npm/bootstrap@4.6.0/dist/js/bootstrap.bundle.min.js" integrity="sha384-Piv4xVNRyMGpqkS2by6br4gNJ7DXjqk09RmUpJ8jgGtD7zP9yug3goQfGII0yAns" crossOrigin="anonymous"></script>
    </div>
  )
}

export default TechnicalError;